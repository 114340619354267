<template>
    <v-container fluid fill-height class="pa-0">
        <v-row justify="center">
            <v-card width="100vw" min-height="100vh" class="pt-10">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-text class="text-justify">
                        <v-row align="center" class="mx-0" justify="center">
                            <v-col cols="12" sm="9">
                                <v-text-field
                                    :value="title"
                                    :counter="50"
                                    :rules="nameRules"
                                    placeholder="Title"
                                    @change="(v) => (title = v)"
                                    clearable
                                    required
                                    class="titleInput"
                                ></v-text-field>
                                <v-tabs>
                                    <v-tab href="#editor">Editor</v-tab>
                                    <v-tab-item value="editor" class="mt-5">
                                        <v-textarea
                                            class="mb-0"
                                            name="input-7-1"
                                            placeholder="Content in Markdown or HTML Format"
                                            rows="15"
                                            :value="mdSource"
                                            @change="(v) => (mdSource = v)"
                                        ></v-textarea>
                                    </v-tab-item>
                                    <v-tab href="#preview">Preview</v-tab>
                                    <v-tab-item value="preview" class="mt-5">
                                        <VueShowdown
                                            flavor="github"
                                            :markdown="mdSource"
                                            class="markdown-body"
                                            :options="VueShowdownOptions"
                                        />
                                    </v-tab-item>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="ma-2 mr-10 pr-2"
                                        text
                                        icon
                                        color="blue lighten-2"
                                        @click="addAttachment('attachment')"
                                        :loading="loading"
                                        :disabled="loading"
                                    >
                                        <v-icon dark left> mdi-attachment </v-icon> Attach File
                                    </v-btn>
                                    <input
                                        ref="uploader"
                                        class="d-none"
                                        type="file"
                                        accept="image/*"
                                        @change="onFileChanged($event, 'attachment')"
                                    />
                                </v-tabs>
                                <v-row align="center" class="mx-0 px-0" justify="center">
                                    <v-col
                                        cols="12"
                                        md="6"
                                        :class="$vuetify.breakpoint.mdAndUp ? 'pl-0' : 'px-0'"
                                    >
                                        <v-select
                                            :value="category"
                                            @change="(v) => (category = v)"
                                            :items="categories"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="Category"
                                            append-outer-icon="mdi-plus"
                                            @click:append-outer="dialog = true"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        :class="$vuetify.breakpoint.mdAndUp ? 'pr-0' : 'px-0'"
                                    >
                                        <v-text-field
                                            :value="tags_raw"
                                            @change="(v) => (tags_raw = v)"
                                            placeholder="#tags"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row align="center" class="mx-0 px-0" justify="center">
                                    <v-col
                                        cols="12"
                                        md="6"
                                        sm="6"
                                        :class="$vuetify.breakpoint.mdAndUp ? 'pl-0' : 'px-0'"
                                    >
                                        <v-btn
                                            class=""
                                            color="orange lighten-2"
                                            :loading="loading"
                                            :disabled="loading"
                                            @click="addAttachment('header-image')"
                                        >
                                            <v-icon dark left> mdi-cloud-upload </v-icon> Header
                                            Image
                                        </v-btn>
                                        <input
                                            ref="uploaderHeader"
                                            class="d-none"
                                            type="file"
                                            accept="image/*"
                                            @change="onFileChanged($event, 'header-image')"
                                        />
                                        <a
                                            :href="hiLink"
                                            class="px-5 text-button"
                                            target="_blank"
                                            >{{ filename }}</a
                                        >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        sm="6"
                                        :class="$vuetify.breakpoint.mdAndUp ? 'pr-0' : 'px-0'"
                                        :align="$vuetify.breakpoint.smAndUp ? 'right' : 'left'"
                                    >
                                        <v-btn
                                            class=""
                                            color="primary lighten-2"
                                            @click="saveNote()"
                                        >
                                            <v-icon dark left> mdi-content-save </v-icon> Submit
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
                <v-dialog v-model="dialog" max-width="600">
                    <v-form ref="form-new-category">
                        <v-card>
                            <v-tabs>
                                <v-tab href="#add-category">Add Category</v-tab>
                                <v-tab-item value="add-category" class="mt-5">
                                    <v-card-text class="mt-2" align="justify">
                                        <v-col cols="12" md="12" sm="12">
                                            <v-text-field
                                                :value="new_category"
                                                @change="(v) => (new_category = v)"
                                                placeholder="New Category Name"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="12" sm="12" align="right">
                                            <v-btn
                                                class=""
                                                color="primary lighten-2"
                                                @click="addNewCategory()"
                                            >
                                                <v-icon dark left> mdi-content-save </v-icon> Submit
                                            </v-btn>
                                        </v-col>
                                    </v-card-text>
                                </v-tab-item>
                                <v-tab href="#remove-category">Remove Category</v-tab>
                                <v-tab-item value="remove-category" class="mt-5">
                                    <v-card-text class="mt-2" align="justify">
                                        <v-col cols="12" md="12" sm="12">
                                            <v-select
                                                :value="delCatId"
                                                @change="(v) => (delCatId = v)"
                                                :items="categories"
                                                placeholder="Select Category"
                                                item-text="name"
                                                item-value="id"
                                                multiple
                                                chips
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" md="12" sm="12" align="right">
                                            <v-btn
                                                class=""
                                                color="red white--text"
                                                @click="delCategory()"
                                            >
                                                <v-icon dark left> mdi-trash-can </v-icon> Delete
                                            </v-btn>
                                        </v-col>
                                    </v-card-text>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-card>
            <div>
                <v-btn fab class="red white--text" fixed @click="$router.go(-1)" right top>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import hljs from "highlight.js";
import "highlight.js/styles/xt256.css";
import "@/assets/css/markdown.css";
import axios from "axios";

const highlightCode = () => {
    const preEl = document.querySelectorAll("pre code");

    preEl.forEach((el) => {
        hljs.highlightElement(el);
    });
};

export default {
    name: "NoteForm",
    components: {
        VueShowdown
    },
    data: () => ({
        VueShowdownOptions: { emoji: true, tables: true },
        mdSource: "",
        fab: false,
        title: "",
        imageNote: "",
        author: "",
        date: "",
        category: "",
        tags: [],
        tags_raw: "",
        nameRules: [
            (v) => !!v || "Title is required",
            (v) => (v && v.length <= 50) || "Title must be less than 30 characters"
        ],
        valid: true,
        categories: [],
        filename: "",
        dialog: false,
        new_category: "",
        delCatId: [],
        hiLink: "",
        loading: false,
        patch: false
    }),
    mounted() {
        highlightCode();
    },
    updated() {
        highlightCode();
    },
    watch: {
        tags_raw(value) {
            if (value == "") {
                this.tags = [];
            } else {
                this.tags = value.split("#");
                this.tags = this.tags.map((val) => val.trim());
                this.tags = this.tags.filter((val) => val);
            }
        }
    },
    async created() {
        let category = await axios.get("/api/category");
        this.categories = category.status == 200 ? category.data : [];
        if (this.$route.params.id) {
            this.patch = true;
            let idNote = this.$route.params.id;
            let notesDetail = await axios.get("/api/notes/" + idNote);
            this.title = notesDetail.data.title;
            this.category = this.categories.filter(
                (v) => v.name == notesDetail.data.category
            )[0].id;
            this.mdSource = notesDetail.data.content;
            let tagJSON = JSON.parse(atob(notesDetail.data.tags));
            this.tags_raw = tagJSON.map((v) => "#" + v).join(" ");
            this.filename = notesDetail.data.img;
            this.hiLink = "/assets/img/" + this.filename;
        }
    },
    methods: {
        addAttachment(source) {
            source == "attachment"
                ? this.$refs.uploader.click()
                : this.$refs.uploaderHeader.click();
        },
        async upload(formData) {
            this.$toast.info("Uploading the File...");
            let resp = await axios.post("/api/upload", formData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            let respJson;
            if (resp.status == 200) {
                this.$toast.success("File Uploaded");
                respJson = resp.data;
            } else {
                this.$toast.error("Failed to Upload");
            }
            return respJson;
        },
        async onFileChanged(e, source) {
            this.loading = true;
            let selectedFile = e.target.files[0];
            let fd = new FormData();
            fd.append("file", selectedFile);
            let resp = await this.upload(fd);
            if (resp.success) {
                if (source == "attachment") {
                    let url = "/" + encodeURI(resp.path);
                    let md = "[" + selectedFile.name + "](" + url + ")";
                    this.mdSource += selectedFile.type.includes("image") ? "!" + md : md;
                } else if (source == "header-image") {
                    this.hiLink = "/" + encodeURI(resp.path);
                    this.filename = selectedFile.name;
                }
            }
            this.loading = false;
        },
        async addNewCategory() {
            this.$toast.info("Saving Category...");
            let response = await axios.put(
                "/api/category",
                { name: this.new_category },
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
            if (response.status == 200) {
                this.$toast.success("Category Added");
                this.categories = this.categories.concat(response.data);
                this.dialog = false;
                this.new_category = "";
            } else {
                this.$toast.error("Failed to Add Category");
            }
        },
        async delCategory() {
            this.$toast.info("Removing Category...");
            let response = await axios.post(
                "/api/category",
                { ids: this.delCatId },
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );

            let resp_body = response.data;
            if (response.status == 200 && resp_body.success) {
                this.$toast.success("Category Removed");
                this.categories = this.categories.filter((val) =>
                    !this.delCatId.includes(val.id) ? val : null
                );
            } else {
                this.$toast.error("Failed to Remove Category");
            }
        },
        async saveNote() {
            this.$toast.info("Saving the Note...");
            let tagsb64 = btoa(JSON.stringify(this.tags));
            let jsonData = {
                img: this.filename,
                title: this.title,
                category: this.category.toString(),
                content: this.mdSource,
                tags: tagsb64
            };
            try {
                let response;
                if (this.patch) {
                    jsonData["id"] = parseInt(this.$route.params.id);
                    response = await axios.patch("/api/notes", jsonData, {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                } else {
                    response = await axios.put("/api/notes", jsonData, {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                }

                if (response.status == 201) {
                    this.$toast.success("Note Added", {
                        onClose: () => {
                            this.$router.push({ path: "/notes" });
                        }
                    });
                } else if (response.status == 204 && this.$route.params.id) {
                    this.$toast.success("Note Edited", {
                        onClose: () => {
                            this.$router.push({ path: "/notes" });
                        }
                    });
                } else {
                    this.$toast.error("Oh Shit! Failed! :(");
                }
            } catch (error) {
                this.$toast.error("Oh Shit! Failed! :(");
            }
        }
    }
};
</script>

<style scoped>
.markdown-body {
    margin: auto;
}
.row {
    margin-left: 0;
    margin-right: 0;
}

.v-text-field.titleInput >>> input {
    font-size: 1.8em;
}

.v-text-field >>> textarea {
    font-size: 1em;
}
.v-text-field >>> label {
    font-size: 1em;
}
</style>
